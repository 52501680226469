import groq from "groq";
import { customCampaignIsOngoingGroqFilter, templateCampaignTypes } from "../campaigns";
import { digitalLibraryFilter, digitalLibraryServiceTypes } from "../digitalLibrary";
import { groqEventStart, resolveRepeatedEvents } from "../events/groq";
import { filters } from "../groq";
import { getPromotionFilter } from "./utils";

const orderByPromotionEndsAt = groq`order(promotion.endsAt desc)`;
const promoteRepeatedEventOccurenceFilter = groq`${filters.repeatedEventNotFinished} && ${getPromotionFilter(
  "@.customization."
)}`;
// Order lists and recommendations by promotion.endsAt if promoted to allow editors to lift old lists and recommendations to the top
export const orderListsAndRecommendations = groq`order(coalesce(promotion.endsAt, _createdAt) desc)`;

const promotionFilter = getPromotionFilter();
const eventsQuery = groq`*[
    _type == "event" && select(
      repeated => count(repeatedDates[${promoteRepeatedEventOccurenceFilter}]) > 0, 
      ${promotionFilter}
    )
  ]
  ${resolveRepeatedEvents(promoteRepeatedEventOccurenceFilter)}
  | order(startDate asc, ${groqEventStart} asc)
`;

const servicesQuery = groq`*[_type == "service" && ${promotionFilter}] | ${orderByPromotionEndsAt}`;

const digitalLibraryServicesQuery = groq`*[${digitalLibraryFilter} && ${promotionFilter}] | ${orderByPromotionEndsAt}`;

const listsAndRecommendationsQuery = groq`*[_type in ["list", "recommendation"] && ${promotionFilter}] | ${orderListsAndRecommendations}`;

const staticPageQuery = groq`*[_type == "staticPage" && ${promotionFilter}] | ${orderByPromotionEndsAt}`;

const templateCampaignsQuery = groq`*[_type in ${JSON.stringify(
  templateCampaignTypes
)} && (${promotionFilter})] | order(startDate)`;

const customCampaignsQuery = groq`*[_type == "customCampaign" && ${customCampaignIsOngoingGroqFilter}] | order(startDate)`;

export const getPromotedItemsQuery = (maxNoItems: number, resolvers: PromotedItemsResolvers) => groq`
  [
    ...${templateCampaignsQuery},
    ...${customCampaignsQuery},
    ...${eventsQuery},
    ...${servicesQuery},
    ...${digitalLibraryServicesQuery},
    ...${staticPageQuery},
    ...${listsAndRecommendationsQuery},
  ]
  [defined(@)] // Filter out null-entries
  | order( coalesce(promotion.advanced.priority, 1) desc)
  [0...${maxNoItems}]
  {
    ...select(
      _type in ["event", "resolvedRepeatedEventOccurence"] => ${resolvers.event},
      _type == "sommerles" => ${resolvers.sommerles},
      _type == "adventCalendar" => ${resolvers.adventCalendar},
      _type == "bibliobattles" => ${resolvers.bibliobattles},
      _type == "customCampaign" => ${resolvers.customCampaign},
      _type == "event" => ${resolvers.event},
      _type == "repeatedEventOccurence" => ${resolvers.event},
      _type == "service" => ${resolvers.service},
      _type in ${JSON.stringify(digitalLibraryServiceTypes)} => ${resolvers.digitalLibraryService},
      _type == "list" => ${resolvers.list},
      _type == "recommendation" => ${resolvers.recommendation},
      _type == "staticPage" => ${resolvers.staticPage},
      {...}
    )
  }
`;

export type PromotedItemsResolvers = Record<
  | "event"
  | "service"
  | "digitalLibraryService"
  | "list"
  | "recommendation"
  | "sommerles"
  | "adventCalendar"
  | "bibliobattles"
  | "customCampaign"
  | "staticPage",
  string
>;
